import React from "react"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Haaallo <span role="img" aria-label="Waving hand emojy">👋</span></h1>
    <p>Schön, dass du mich gefunden hast! Wahrscheinlich hast du die Webseite auf meiner Dankeskarte
      zur Geburt gesehen und wolltest mal gucken was es hier so spannendes zu entdecken gibt.</p>
    <p>Da ich gerade selbst noch sehr damit beschäftigt bin, die Welt zu entdecken, ist hier leider
      bisher noch nicht so viel geschehen.</p>

    <p>Wenn es etwas Neues zu sehen gibt, erfährst du das auf einer der Familienfeiern, beim Cousinentreffen
      und natürlich über die stolzen Großeltern.</p>

    <p>Fühl' dich gedrückt und bis bald,</p>
    <h3>Carl</h3>

    <p>PS: Schön, dass du mich hier besucht hast!</p>

  </Layout>
)

export default IndexPage
